.gamePaper {
  padding: 12px;
}

div.topBar {
 display: flex;
 justify-content: space-between;
 align-items: center;
}

div.history {
  display: flex;
  padding: 10px;
  align-items: center;
}

div.history span {
  flex: 0 0 2.4em;
  height: 1.6em;
  margin: 0 .5em 0 0;
  color: #fff;
  opacity: .85;
  font-size: .85em;
  font-weight: bold;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
}

div.history span.fail {
  background-color: red;
}
div.history span.success {
  background-color: green;
}

div.score {
  font-weight: bold;
  font-size: large;
  padding: 0 10px;
}

.gameResult {
  text-align: center;
  padding: 10px;
  font-size: 130%;
}

.hintsContainer {
  padding: 20px 0;
}

.unknownImage {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;

}
.unknownImage h2 {
  display: block;
  font-size: 180%;

}

.bubble{
  background-color: #F2F2F2;
  border-radius: 5px;
  box-shadow: 0 0 2px #B2B2B2;
  display: table;
  padding: 10px 18px;
  position: relative;
  vertical-align: top;
}

.bubble::before {
  background-color: #F2F2F2;
  content: "\00a0";
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform:             rotate( 29deg ) skew( -35deg );
      -moz-transform:    rotate( 29deg ) skew( -35deg );
      -ms-transform:     rotate( 29deg ) skew( -35deg );
      -o-transform:      rotate( 29deg ) skew( -35deg );
      -webkit-transform: rotate( 29deg ) skew( -35deg );
  width:  20px;
}

.bubble.green, .bubble.green::before {
  background-color: #88ff00;
}

.bubble.orange, .bubble.orange::before {
  background-color: orange;
}

.bubble.red, .bubble.red::before {
  background-color: #f17b37;
}

.me {
  margin: 5px 45px 5px 20px;         
}

.me::before {
  box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
  left: -9px;           
}

.you {
  margin: 5px 20px 5px 45px;  
  margin-left: auto;
}

.you::before {
  box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
  right: -9px;    
}


.additionalHint {
  margin: 5px; 
}

.additionalHint button {
  width: 100%;
}

.answerbuttons {
  margin: 20px 0 ;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr); 

}

.answerbuttons > div {
  padding: 10px;
}

.answerbuttons button {
  width: 100%;
  height: 100%;
}

.continuebutton button {
  width: 100%;
}

.counter {
  font-size: larger;
  font-weight: bold;
  display:flex;
  align-items: center
}


table {
  width: 100%;
}

.highscore input {
  font-size: inherit;
}