body {
  background-color:  #f8f8f8;
}

div.gameContainer {
  padding: 5px;
}

.gameContainer  {
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.counter {
  font-weight: bold;
  font-size: larger;
}

